import { useRouter } from "solito/router";

import {
  CalendarText,
  Paragraph,
  Paragraph2,
  XStack,
  YStack,
  styled,
} from "@medbillai/ui";

import { billUrl } from "@medbillai/utils";

import { SingularEvents, singularService } from "../../../lib/singular";
import { type NormalizedEob } from "../utils/types";
import { formatCostTotal } from "../utils/utils";
import { BillListItemIcon } from "./shared/BillListItemIcon";

type BillListItemProps = {
  eob: NormalizedEob;
  borderHide?: boolean;
  flashListView?: boolean;
};

const SubTitleParagraph = styled(Paragraph2, {
  theme: "alt1",
  whiteSpace: "nowrap",
  lineHeight: 16,
});

const TitleParagraph = styled(Paragraph, {
  overflow: "hidden",
  numberOfLines: 1,
});

export const BillListItem = ({
  eob,
  borderHide = false,
  flashListView = false,
}: BillListItemProps) => {
  const { push } = useRouter();
  const openBill = () => {
    singularService.event(SingularEvents.openBill);
    push(
      billUrl(
        eob.resourceId,
        eob.location || "Unknown Location",
        eob.decision || "",
      ),
    );
  };
  return (
    <YStack
      px="$4"
      onPress={openBill}
      cur="pointer"
      pressStyle={{
        backgroundColor: "$backgroundPress",
      }}
    >
      <XStack
        ai="center"
        jc="flex-start"
        gap="$4"
        py="$4"
        flex={1}
        borderTopWidth={borderHide ? 0 : 0.5}
        borderBottomWidth={flashListView ? 0.5 : 0}
        borderColor="$strokeSecondary"
        backgroundColor="transparent"
      >
        <BillListItemIcon status={eob.decision} />
        <YStack
          alignItems="flex-end"
          justifyContent="flex-end"
          gap="$2"
          flex={1}
          height="$4"
        >
          <XStack gap="$5" ai="center" jc="space-between" f={1} width="100%">
            <TitleParagraph overflow="hidden" textOverflow="hidden" f={1}>
              {eob.title ? eob.title : eob.location ?? "-"}
            </TitleParagraph>
            <TitleParagraph>
              {formatCostTotal(eob.totalCost, "$0")}
            </TitleParagraph>
          </XStack>
          <XStack gap="$4" ai="center" jc="space-between" f={1} width="100%">
            <SubTitleParagraph
              numberOfLines={1}
              overflow="hidden"
              textOverflow="hidden"
              f={1}
            >
              {eob.location ?? "-"}
            </SubTitleParagraph>
            <CalendarText
              date={eob.serviceDate || ""}
              element={props => <SubTitleParagraph {...props} />}
              utc={true}
            />
          </XStack>
        </YStack>
      </XStack>
    </YStack>
  );
};
